@import '../../node_modules/bootstrap/scss/bootstrap';

/* CONFIGURAÇÕES GLOBAIS */

:root {
    --t1-1: rgb(149,152,157);
    --t1-2: rgb(136,130,130);
    --t1-3: rgb(84,70,67);
    --t1-4: rgb(76,63,57);
    --t1-5: rgb(155,122,107);
    --t1-6: rgb(113,75,90);
    --t1-7: rgb(105,51,109);
    --t1-8: rgb(114,75,132);
    --t1-9: rgb(170,154,183);
    --t1-10: rgb(215,220,224);
    --t1-11: rgb(177,283,285);
    --t1-12: rgb(130,130,132);
}

* {
    margin: 0;
    padding: 0;
}

h1 {
    letter-spacing: 3px;
    color: var(--t1-8);
    text-transform: uppercase;
    font-size: 2.0rem;
    font-weight: 800;
    text-align: center;
}

h4 {
    color: var(--t1-8);
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 800;
    text-align: center;
}

.texto{
    font-size: 2.5rem;
}

/* BARRA DE SCROLLING */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* BARRA DE NAVEGAÇÃO DO TOPO */

.item{
    min-width: 90px;
    margin-left: 5px;
    margin-right: 5px;
    font-family: 'Alumni Sans', sans-serif;
    font-weight: 800;
    color: white;
    text-shadow: 1px 1px black;
    cursor: pointer;
}


.item:hover {
    color: var(--t1-9);
}

.btn-agendar{
    background-color: var(--t1-7);
    color: white;
    font-weight: 600;
}

.btn-agendar:hover{
    background-color: var(--t1-8);
    color: white;
}

.bg-custom{
    background-color: rgba(0,0,0, 0.4);
}

/* CONTAINERS DO CORPO DO SITE */

#app{
    margin: auto;
}

#apresentacao,
#quando-procurar,
#area-atuacao,
#agendamentos,
#depoimentos {
    padding-top: 10px;
}

#footer{
    padding: 0;
    padding-top: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 70px;
    background-color: var(--t1-3);
    color: white;
    font-weight: 600;
}

/* PARALLAX */
.parallax{
    height:100vh;
}


/* CONFIGURAÇÕES DOS CARDS DE DEPOIMENTOS */

.cards-depoimentos{
    max-width: 540px;
}